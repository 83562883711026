import {call, take, race, put, takeLatest, takeEvery} from "redux-saga/effects"
import { fetchRequest } from "../../features/entity/store/actions"
import { fetchM } from "../../config/pages/shared/fetchM"
import { EntityId } from "../../features/entity/type"
import {successMessageAction} from "../../config/pages/shared/common_store";
import i18next from "i18next";
import {User} from "./user";
import {getApiResponse} from "../../config/pages/authentication/reducers/sagas";
import {buildRaw} from "../../features/entity/helpers/buildRaw";

import {warningMessageAction} from '../../config/pages/shared/common_store'

export const CLOSE_SESSION = "CLOSE_SESSION"

export const USER_CREATE_ACTION = "MANUAL_USER_CREATE_ACTION"
export const USER_UPDATE_ACTION = "MANUAL_USER_UPDATE_ACTION"
export const USER_CHANGE_PASSWORD_ACTION= "USER_CHANGE_PASSWORD_ACTION"
export const USER_CHANGE_PASSWORD_FAIL_ACTION="USER_CHANGE_PASSWORD_FAIL_ACTION"

export interface ICloseSessionAction {
  type: typeof CLOSE_SESSION,
  userId: string
}

export const closeSessionAction = (userId: string): ICloseSessionAction => {
  return {
    type: CLOSE_SESSION,
    userId
  }
}


const SET_TAGS_ACTION = "user_SET_TAGS_ACTION"

export function setTagsAction(tags: EntityId[]){
  return {
    type: SET_TAGS_ACTION,
    tags
  }
}

function* setUserTags(action: any): any {
  const [upd, create] = yield race([take("users_UPDATE_ACTION"), take("users_CREATE_ACTION")])
  const response: any = upd ? upd.response : create.response
  if (response.success) {
    const url = `/users/${response.model.id}/tags`
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tag_ids: action.tags
      })
    }
    yield fetchM(url, options)
    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}

function* closeSession({userId}: ICloseSessionAction) {
  const url = `users/${userId}/close_work_session`
  const options = {
    method: "PATCH"
  }

  const response = yield fetchM(url, options, undefined, undefined, true)
  if (response.success)
    yield put(successMessageAction(i18next.t('success close session')))
  else
    yield put(successMessageAction(i18next.t('failed close session')))
}

export interface ICreateUser {
  type: typeof USER_CREATE_ACTION,
  user: User,
  file?: any
}

export const createUserAction = (user: User, file?: any): ICreateUser => {
  return {
    type: USER_CREATE_ACTION,
    user: user,
    file: file
  }
}

function* createUser({user, file}: ICreateUser) {
	const password_requirements = [
		{ re: /[0-9]/, label: "Includes number" },
		{ re: /[a-z]/, label: "Includes lowercase letter" },
		{ re: /[A-Z]/, label: "Includes uppercase letter" },
		{ re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
	  ];

	  function getPasswordStrength(password: string) {
		let multiplier = password.length > 8 ? 0 : 1;

		password_requirements.forEach((requirement) => {
		  if (!requirement.re.test(password)) {
			multiplier += 1;
		  }
		});

		return Math.max(100 - (100 / (password_requirements.length + 1)) * multiplier, 0);
	  }

	  if (user?.password) {
		const password_strength = getPasswordStrength(user.password)

		if (user.password.length < 8) {
			console.log(user)

			yield put(warningMessageAction(i18next.t('Password must contain&#58; lowercase, uppercase, numbers, special characters, and be at least 8 characters long.')))


			return
		}
		else if (password_strength < 80) {
			yield put(warningMessageAction(i18next.t('Password must contain&#58; lowercase, uppercase, numbers, special characters, and be at least 8 characters long.')))

			return
		}
	  }

  let body = buildRaw(user, User)
  let resp = yield getApiResponse("/users", "POST", body)
  if (resp.success) {
    if (file) {
      let model = resp.model
      const formData = new FormData()

      formData.append('avatar', file)
      const url = `/users/${model.id}/avatar`
      const options = {
        method: "PATCH",
        body: formData
      }
      yield fetchM(url, options)
    }

    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}

export const USERS_UPDATE_ACTION = "MANUAL_USERS_UPDATE"

export interface IUpdateUsers {
	type: typeof USERS_UPDATE_ACTION,
	hub_id?: string
}

export const updateUsersAction = (hub_id?: string): IUpdateUsers => {
	return {
	  type: USERS_UPDATE_ACTION,
	  hub_id: hub_id
	}
  }

function* updateUsers({hub_id}: IUpdateUsers) {
	let url = 'users'
	let query = ''
	let options = {}

	if (hub_id) {
		options['hub_id'] = hub_id
	}

	for (const [key, value] of Object.entries(options)) {
		query += (query.length > 0 ? '&' : '?') + key + '=' + value
	}

	if (query.length > 0) {
		url += query
	}



	yield put(fetchRequest(url, {}, "users_RELOAD_ACTION"))
  }

export interface IUpdateUser {
  type: typeof USER_UPDATE_ACTION,
  user: User,
  file?: any
}

export interface IChangePasswordUser {
	type: typeof USER_CHANGE_PASSWORD_ACTION,
	current_password: string
	new_password: string
	confirm_password: string
}

export const updateUserAction = (user: User, file?: any): IUpdateUser => {
  return {
    type: USER_UPDATE_ACTION,
    user: user,
    file: file
  }
}

function* updateUser({user, file}: IUpdateUser) {
	const password_requirements = [
		{ re: /[0-9]/, label: "Includes number" },
		{ re: /[a-z]/, label: "Includes lowercase letter" },
		{ re: /[A-Z]/, label: "Includes uppercase letter" },
		{ re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
	  ];

	  function getPasswordStrength(password: string) {
		let multiplier = password.length > 8 ? 0 : 1;

		password_requirements.forEach((requirement) => {
		  if (!requirement.re.test(password)) {
			multiplier += 1;
		  }
		});

		return Math.max(100 - (100 / (password_requirements.length + 1)) * multiplier, 0);
	  }

	  if (user?.password) {
		const password_strength = getPasswordStrength(user.password)

		if (user.password.length < 8) {
			console.log(user)

			yield put(warningMessageAction(i18next.t('Password must contain&#58; lowercase, uppercase, numbers, special characters, and be at least 8 characters long.')))


			return
		}
		else if (password_strength < 80) {
			yield put(warningMessageAction(i18next.t('Password must contain&#58; lowercase, uppercase, numbers, special characters, and be at least 8 characters long.')))

			return
		}
	  }


  let body = buildRaw(user, User)


  let resp = yield getApiResponse("/users/" + user.id, "PATCH", body)
  if (resp.success) {
    if (file) {
      let model = resp.model
      const formData = new FormData()

      formData.append('avatar', file)
      const url = `/users/${model.id}/avatar`
      const options = {
        method: "PATCH",
        body: formData
      }
      yield fetchM(url, options)
    }

    yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))
  }
}


//

export const changePasswordUserAction = (current_password: string, new_password: string, confirm_password: string): IChangePasswordUser => {
	return {
	  type: USER_CHANGE_PASSWORD_ACTION,
	  current_password: current_password, new_password: new_password, confirm_password: confirm_password
	}
  }

  function* changePasswordUser({current_password, new_password, confirm_password}: IChangePasswordUser) {
	let resp = yield getApiResponse('/users/change_password', "PUT", {
		current_password: current_password,
		new_password: new_password,
		confirm_password: confirm_password
	  }	)

	if (!resp?.error) {
	  yield put(fetchRequest('users', {}, "users_RELOAD_ACTION"))

	  window.location.replace("/change-password?success=true")
	}
	else {
		yield put({
			type: USER_CHANGE_PASSWORD_FAIL_ACTION,
			...(resp['error'] && {
			  error: resp['error']
		  }),
		})
	}
  }

export function* userSaga(): any {
  yield takeLatest(SET_TAGS_ACTION, setUserTags)
  yield takeEvery(CLOSE_SESSION, closeSession)
  yield takeLatest(USER_CREATE_ACTION, createUser)
  yield takeLatest(USER_UPDATE_ACTION, updateUser)
  yield takeLatest(USER_CHANGE_PASSWORD_ACTION, changePasswordUser)
  yield takeLatest(USERS_UPDATE_ACTION, updateUsers)
}
