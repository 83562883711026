import { all } from "redux-saga/effects"
import { fieldsSagaRegestration } from "../../../models/field/saga"
import { ticketTemplateSagaRegestration } from "../../../models/ticket_template/saga"
import { linesSagaRegestration } from "../../../models/line/saga"
import { userSaga } from "../../../models/user/saga"
import { desksSaga } from "../../../features/entity/components/saga"
import ticketAdminSaga from '../../../models/ticket/saga'
import {smsSaga} from "../../../models/sms/saga";

export default function* () {
  yield all([fieldsSagaRegestration(), 
    ticketTemplateSagaRegestration(), 
    linesSagaRegestration(),
    smsSaga(),
    userSaga(),
	desksSaga(),
    ticketAdminSaga()
  ])
}
