import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { Alert } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import logo from '../../../../logo.svg'

import { useLocation } from 'react-router-dom';

import {useCurrentUser} from "../../../pages/shared/use_current_user";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        VoltMobi Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: "none",

    '&:hover': {
      boxShadow: "none"
    },

    '&:active': {
      boxShadow: "none"
    }

  },
}));

interface IProps {
  onClick: (current_password: string, new_password: string, confirm_password: string) => void
  errorMessage: string | null
}
type TEvent = React.ChangeEvent<{ value: unknown }>

export default function ChangePasswordForm({ onClick, errorMessage }: IProps) {
  const classes = useStyles()
  const currentUser = useCurrentUser()

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const onCurrentPasswordChange = (e: TEvent) => setCurrentPassword(String(e.target.value))
  const onNewPasswordChange = (e: TEvent) => setNewPassword(String(e.target.value))
  const onConfirmPasswordChange = (e: TEvent) => setConfirmPassword(String(e.target.value))

  const location = useLocation();
  const isSuccess = new URLSearchParams(location.search).get("success")

  useEffect(() => {
    if (isSuccess) {
		setTimeout(() => window.location.replace("/"), 3000)
	}
  }, [isSuccess])


  const enterClick = (e: React.KeyboardEvent) =>{
    if(e.keyCode === 13){
		if (!currentUser?.password_changed_at) {
      onClick(currentPassword, newPassword, confirmPassword)
		}
    }
  }
  return (
    <Box style={{width: "100%", height: "100%", position: "absolute"}}
         display="flex" flexDirection="column" justifyContent="center">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="logo" style={{ height: 35, marginBottom: 45 }} />

		  	{/*currentUser?.password_changed_at ? (
				<Alert severity="error" qa-tag="sigin-alert">You have changed your password already</Alert>
			) : (
				<>
					{isSuccess ? (
						<Alert severity="success" qa-tag="sigin-alert">Your password has been changed.</Alert>
					) : (
						<>
							{errorMessage ? 
								<Alert severity="error" qa-tag="sigin-alert">{errorMessage}</Alert>
								:
								null
							}
						</>
					)}
				</>
			)*/}
			
			<Alert severity="error" qa-tag="sigin-alert">The time has expired. You can no longer change the password</Alert>
			
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="current_password"
              label="Current Password"
              type="password"
              id="current_password"
              autoComplete="current-password"
              qa-tag="current_password"
              onChange={onCurrentPasswordChange}
              onKeyUp={enterClick}
			  disabled={(currentUser?.password_changed_at?.length) ? true : false}
            />
			<TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="new-password"
              label="New Password"
              name="new-password"
              onChange={onNewPasswordChange}
              onKeyUp={enterClick}
              qa-tag="new-password"
			  disabled={(currentUser?.password_changed_at?.length) ? true : false}
            />
			<TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirm-password"
              label="Confirm Password"
              name="confirm-password"
              onChange={onConfirmPasswordChange}
              onKeyUp={enterClick}
              qa-tag="confirm-password"
			  disabled={(currentUser?.password_changed_at?.length) ? true : false}
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              disableRipple={true}
			  disabled={(currentUser?.password_changed_at?.length) ? true : false}
              className={classes.submit}
              onClick={() => onClick(currentPassword, newPassword, confirmPassword)}
              qa-tag="change-password-button"
            >
              Change Password
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}