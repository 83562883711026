import {put, takeLatest} from "redux-saga/effects"
import { fetchRequest } from "../../../features/entity/store/actions"

export const DESKS_UPDATE_ACTION = "MANUAL_DESKS_UPDATE"

export interface IUpdateDesks {
	type: typeof DESKS_UPDATE_ACTION,
	hub_id?: string
}

export const updateDesksAction = (hub_id?: string): IUpdateDesks => {
	return {
	  type: DESKS_UPDATE_ACTION,
	  hub_id: hub_id
	}
  }

function* updateDesks({hub_id}: IUpdateDesks) {
	let url = 'desks'
	let query = ''
	let options = {}

	if (hub_id) {
		options['hub_id'] = hub_id
	}

	for (const [key, value] of Object.entries(options)) {
		query += (query.length > 0 ? '&' : '?') + key + '=' + value
	}

	if (query.length > 0) {
		url += query
	}

	

	yield put(fetchRequest(url, {}, "desks_RELOAD_ACTION"))
  }

export function* desksSaga(): any {
  yield takeLatest(DESKS_UPDATE_ACTION, updateDesks)
}
