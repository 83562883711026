import {AppState} from "../../../config/store"
import {filteredEntities} from "../../../features/entity/containers/list"
import {useDispatch, useSelector} from "react-redux"
import React, {forwardRef, useEffect, useRef, useState} from "react"
import {Ticket, TicketKindSort} from "../ticket"
import {EntityId, TAditionalStore} from "../../../features/entity/type"
import MaterialTable, {Icons} from "material-table"
import {Link} from "react-router-dom"
import {Box, Container, TextField, Typography} from "@material-ui/core"
import {BaseEntity} from "../../../features/entity"
import {useTranslation} from "react-i18next"
import TicketDateRange from "./ticket_daterange"
import {RemoteSyncTicketContainer} from ".."
import moment from "moment"
import {getArrowTable, getArrowTableCustom, getCalendarIcon} from "../../../assets/icons";
import SelectFiltered from "./select_state_filtered";
import SelectSearch from "./select_single_search";
import SelectHubFiltered from "./select_hub_filtered";
import SelectCityFiltered from "./select_city_filtered";
import {getTicketStates} from "../../states";
import {loadYetTicketsAction} from "../saga";
import {bildUrl, removeEmpty} from "../../../config/pages/statistics/pages/helper";
import {arrayToString, replaceAll} from "../../../shared/helper";

import {Hub} from "../../hub";
import {City} from "../../city";

const perPage = 20
const initialCustomUrl = `tickets`
const defaultDates = {since: moment(new Date()).format("YYYY-M-D"), till: moment(new Date()).format("YYYY-M-D")}

interface IOwnProps {
line_id?: EntityId
tabs?: React.ReactElement
}

export default (props: IOwnProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const refTable = useRef<MaterialTable<BaseEntity>>(null)
	const {stStore, tickets, hubs, cities} = useSelector((store: AppState) => {
	let filtered: Ticket[] = (filteredEntities(store.tickets.entities) as any)
	if (props.line_id) {
		filtered = filtered.filter(t => t.line_id === props.line_id)
	}
	const additionals: TAditionalStore = {
      hubs: store.hubs,
      lines: store.lines,
      tags: store.tags
    }

	return { stStore: {
		filtered,
		additionals,
		tabs: props.tabs,},
		tickets: Object.keys(store.tickets.entities).map(k => store.tickets.entities[k] as Ticket),
		hubs: Object.keys(store.hubs.entities).map(k => store.hubs.entities[k] as Hub),
		cities: Object.keys(store.cities.entities).map(k => store.cities.entities[k] as City)
	}
	})

  const states = getTicketStates()
  const [stateIds, setStateIds] = useState<string[]>([])
  const [hubFilterIds, setHubFilterIds] = useState<string[]>([])
  const [cityFilterIds, setCityFilterIds] = useState<string[]>([])

  const searches = [{
    id: "0",
    display_name: t('ticket')
  }, {
    id: "1",
    display_name: t('client')
  }, {
    id: "2",
    display_name: t('phone')
  }]
  const [searchId, setSearchId] = useState<string>("0")
  const [searchQuery, setSearchQuery] = useState<string>("")

  const [dates, setDates] = useState()
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const [customUrl, setCustomUrl] = useState<string>(initialCustomUrl + `?per_page=${perPage}`)
  const [loadYetShow, setLoadYetShow] = useState<boolean>(true)

  const [kindSort, setKindSort] = useState<TicketKindSort>(TicketKindSort.CREATED_AT)
  const [isSort, setSort] = useState({
    sortTicketNo: false,
    sortClient: false,
    sortPhoneNumber: false,
    sortState: false,
    sortLine: false,
    sortHub: false,
    sortTag: false,
    sortCreatedAt: false
  })

  const changeS = (query: string) => {
    setSearchQuery(query)
  }
  const col1 = Ticket.getLineColumn().buildColumn(stStore.additionals)
  col1.title = (<div onClick={() => {
    setKindSort(TicketKindSort.LINE)
    setSort({...isSort, sortLine: !isSort.sortLine})
  }}>
    <Box display="flex" flexDirection="row">
      <Box>{t("line_id")}</Box>
      <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortLine)}</Box>
    </Box>
  </div>)
  col1.sorting = false

  const col2 = Ticket.getHubColumn().buildColumn(stStore.additionals)
  col2.title = (<div onClick={() => {
    setKindSort(TicketKindSort.HUB)
    setSort({...isSort, sortHub: !isSort.sortHub})
  }}>
    <Box display="flex" flexDirection="row">
      <Box>{t("hub_id")}</Box>
      <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortHub)}</Box>
    </Box>
  </div>)
  col2.sorting = false

  const col3 = Ticket.getTagColumn().buildColumn(stStore.additionals)
  col3.title = (<div onClick={() => {
    setKindSort(TicketKindSort.TAG)
    setSort({...isSort, sortTag: !isSort.sortTag})
  }}>
    <Box display="flex" flexDirection="row">
      <Box>{t("tag")}</Box>
      <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortTag)}</Box>
    </Box>
  </div>)
  col3.sorting = false

  useEffect(() => {
    getDataForFilters()
  }, [dates, stateIds, searchQuery, isSort, hubFilterIds, cityFilterIds])

  const openDatePicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setDatePickerOpen(true)
  }

  const resetDate = () => {
    setDates("")
  }

  const getDataForFilters = () => {
    let since
    let till
    let state
    let ticket_no
    let name
    let phone
    let sort
    let order

    if (dates && stateIds.length > 0) {
      since = dates.since
      till = dates.till
      state = replaceAll(arrayToString(stateIds, states), " ", "")
    } else if (dates) {
      since = dates.since
      till = dates.till
    } else if (stateIds.length > 0) {
      state = replaceAll(arrayToString(stateIds, states), " ", "")
    }

    sort = kindSort
    let isSortResult = false
    switch (sort) {
      case TicketKindSort.TICKET_NO:
        isSortResult = isSort.sortTicketNo
        break
      case TicketKindSort.CLIENT:
        isSortResult = isSort.sortClient
        break
      case TicketKindSort.PHONE_NUMBER:
        isSortResult = isSort.sortPhoneNumber
        break
      case TicketKindSort.STATE:
        isSortResult = isSort.sortState
        break
      case TicketKindSort.LINE:
        isSortResult = isSort.sortLine
        break
      case TicketKindSort.HUB:
        isSortResult = isSort.sortHub
        break
      case TicketKindSort.TAG:
        isSortResult = isSort.sortTag
        break
      case TicketKindSort.CREATED_AT:
        isSortResult = isSort.sortCreatedAt
        break
    }


    if (isSortResult)
      order = "ASC"
    else
      order = "DESC"

    if (searchQuery)
      switch (searchId) {
        case "0":
          ticket_no = searchQuery
          break
        case "1":
          name = searchQuery
          break
        case "2":
          phone = searchQuery.replace(/\D/g, '')
          break
      }

    const url = bildUrl(initialCustomUrl, removeEmpty({
      since: since,
      till: till,
      state: state,
	  ...(hubFilterIds.length > 0 && {
		hub_id: hubFilterIds.join(','),
	  }),
	  ...(cityFilterIds.length > 0 && {
		city_id: cityFilterIds.join(','),
	  }),
      ticket_no: ticket_no,
      name: name,
      phone: phone,
      sort: sort,
      order: order,
      per_page: perPage
    }))

    setCustomUrl(url)
  }

  const loadYetTickets = () => {
    const customUrlForYetTickets = `${customUrl}&id=${tickets[tickets?.length - 1]?.id}`
    dispatch(loadYetTicketsAction(customUrlForYetTickets, (isShow) => {
      setLoadYetShow(isShow)
    }))
  }

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref))
  }

  return <>
    {
      customUrl != initialCustomUrl + `?per_page=${perPage}`
        ? <RemoteSyncTicketContainer customUrl={customUrl} />
        : null
    }
    <Box display="flex" flexDirection="row">
      <Box style={{marginTop: 15}}>
        {stStore.tabs ? stStore.tabs : null}
      </Box>
      <Box flexGrow={1} />
      <Box>
        <div onClick={openDatePicker} style={{ marginRight: 15, paddingTop: 22, cursor: 'pointer'}}>
          {getCalendarIcon()}
        </div>
        <TicketDateRange
          val={[new Date(), new Date()]}
          setVal={(from, to) => {
            const dates = {since: moment(from).format("YYYY-M-D"), till: moment(to).format("YYYY-M-D")}
            if (dates == defaultDates) {
              setDates(dates)
              getDataForFilters()
            } else {
              setDates(dates)
            }
          }}
          anchor={anchorEl}
          open={datePickerOpen}
          close={() => {setDatePickerOpen(false)}}
          resetDate={resetDate}
        />
      </Box>
      <Box display="flex"
           flexDirection="row"
           className="select-search">
        <Box style={{marginTop: 6}}>
          <SelectSearch
            collection={searches}
            val={searchId}
            setVal={setSearchId}/>
        </Box>
        <Box style={{marginTop: 6}}>
          <TextField
            onChange={(e) => changeS(e.target.value)}
            placeholder={t("search")}
            className="mtable search-field"
            type={searchId === "2" ? "number" : ""}/>
        </Box>
      </Box>
    </Box>
    <Box display="flex" flexDirection="row" marginLeft="19px">
      <SelectFiltered
        collection={states}
        val={stateIds}
        setVal={setStateIds}
        label={t("state")}
      />

	  <SelectHubFiltered
			  collection={hubs}
			  val={hubFilterIds}
			  setVal={setHubFilterIds}
			  label={t("hub")}
			/>
			<SelectCityFiltered
					collection={cities}
					val={cityFilterIds}
					setVal={setCityFilterIds}
					label={t("city")}
				  />
    </Box>
    <Container maxWidth="lg" className="mtable container-table">
      <MaterialTable
        data={stStore.filtered}
        icons={tableIcons}
        options={{
          paging: false,
          emptyRowsWhenPaging: false,
          toolbar: false
        }}
        tableRef={refTable}
        columns={[
          {
            field: "ticket_no",
            title: (<div onClick={() => {
              setKindSort(TicketKindSort.TICKET_NO)
              setSort({...isSort, sortTicketNo: !isSort.sortTicketNo})
            }}>
              <Box display="flex" flexDirection="row">
                <Box>{t("ticket_no")}</Box>
                <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortTicketNo)}</Box>
              </Box>
            </div>),
            sorting: false,
            render: row => <>
              {row ?
                <Link to={`/admin/tickets/${row.id}`}>{(row as Ticket).ticket_no}</Link>
                : null}
            </>
          },
          {
            title: (<div onClick={() => {
              setKindSort(TicketKindSort.CLIENT)
              setSort({...isSort, sortClient: !isSort.sortClient})
            }}>
              <Box display="flex" flexDirection="row">
                <Box>{t("name client")}</Box>
                <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortClient)}</Box>
              </Box>
            </div>),
            sorting: false,
            render: row => <span>{((row as Ticket).client_data?.last_name || "") + " " + ((row as Ticket).client_data?.first_name || "")}</span>
          },
          {
            title: (<div onClick={() => {
              setKindSort(TicketKindSort.PHONE_NUMBER)
              setSort({...isSort, sortPhoneNumber: !isSort.sortPhoneNumber})
            }}>
              <Box display="flex" flexDirection="row">
                <Box>{t("phone")}</Box>
                <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortPhoneNumber)}</Box>
              </Box>
            </div>),
            field: "phone",
            sorting: false,
            render: row => <span>{((row as Ticket).client_data?.phone || "")}</span>
          },
          {
            field: "state",
            title: (<div onClick={() => {
              setKindSort(TicketKindSort.STATE)
              setSort({...isSort, sortState: !isSort.sortState})
            }}>
              <Box display="flex" flexDirection="row">
                <Box>{t("state")}</Box>
                <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortState)}</Box>
              </Box>
            </div>),
            sorting: false,
            render: row => <span>{t((row as Ticket).state)}</span>
          },
          {
            field: "expert",
            title: t("expert"),
            sorting: false,
            customSort: (data1, data2) => stringSort((data1 as Ticket).expert?.name || "", (data2 as Ticket).expert?.name || ""),
            render: row => <span>{(row as Ticket).expert?.name || ""}</span>
          },
          col1,
          col2,
          {
            title: (<div onClick={() => {
                setKindSort(TicketKindSort.CREATED_AT)
                setSort({...isSort, sortCreatedAt: !isSort.sortCreatedAt})
              }}>
              <Box display="flex" flexDirection="row">
                <Box>{t("created_at_date")}</Box>
                <Box style={{marginTop: 2}}>{getArrowTableCustom(isSort.sortCreatedAt)}</Box>
              </Box>
            </div>),
            field: "created_at",
            sorting: false,
            render: row => <span>{new Date((row as Ticket).created_at).toLocaleString()}</span>
          },
          col3
        ]}
      />
    </Container>
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop={2} marginBottom={3}>
    {
      loadYetShow && tickets.length >= perPage
      ? <Typography className="title-history-change" onClick={() => loadYetTickets()}>
          {t('load yet')}
        </Typography>
      : null
    }
    </Box>
  </>
}

export function stringSort(name1: string, name2: string) {
  if (name1 > name2) {
    return 1;
  }
  if (name1 < name2) {
    return -1;
  }

  return 0;
}